import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@ionic-native/network/ngx';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingService } from './services/loading.service';
import { MasterService } from './services/master.service';
import { AuthenticationService } from './services/authentication.service';
import { appConfig } from './app.config';
import { Keyboard } from '@ionic-native/keyboard/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Accountant, Admin, Staff, CustomNavData  } from './_NAV';
import { DataService } from './services/data.service';
import {  ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
// import { CustomNavData } from './_NAV';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages: any = [];
  counter: number = 0;
  currentUser: any = {}
  public profile: any = {}
  public selectedIndex = 0;
  previewUrl: any = null;
  pushes: any = [];
  submenuOpen: boolean[] = [];
  

  userRole: string = 'Admin'; // Set the user role based on your authentication logic
  menuItems: CustomNavData[] = [];
  selectedMenuItem: CustomNavData | null = null;
  subMenuOpen: { [key: string]: boolean } = {};
  public mainMenuSelectedIndex = 0;
  public submenuSelectedIndex = 0;
  public appPagesMenu = [];
  public selectedPage: any;

  constructor(private platform: Platform,
    private splashScreen: SplashScreen,
    private androidFullScreen: AndroidFullScreen,
    public router: Router,
    private statusBar: StatusBar,
    public translate: TranslateService,
    public alertController: AlertController,
    public network: Network,
    private spinner: NgxSpinnerService, 
    public loading: LoadingService,
    public masterservice: MasterService,
    public auth: AuthenticationService,
    public menu: MenuController,
    private keyboard: Keyboard,
    private fcm: FCM,
    private menuCtrl: MenuController,
    private dataService: DataService,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url;
       // console.log('url', url)
        if(url == '/home'){
          this.mainMenuSelectedIndex = 0;
        } else if (url == '/rfq'){
          this.mainMenuSelectedIndex = 3;
        } else if (url == '/view-quotation'){
          this.mainMenuSelectedIndex = 4;
        } else if (url == '/customer') {
          this.mainMenuSelectedIndex = 5;
        } else if (url.startsWith('/view-customer')) {
          this.mainMenuSelectedIndex = 5;
        } else if (url == '/inquiry') {
          this.mainMenuSelectedIndex = 1;
        } else if (url == '/survey') {
          this.mainMenuSelectedIndex = 2;
        } else if (url == '/complain') {
          this.mainMenuSelectedIndex = 7;
        } else if (url == '/invoice') {
          this.mainMenuSelectedIndex = 8;
        } else if (url == '/payment') {
          this.mainMenuSelectedIndex = 9;
        }  else if (url == '/projects') {
          this.mainMenuSelectedIndex = 6;
        } else if (url == '/supplier') {
          this.mainMenuSelectedIndex = 11;
        } else if (url == '/users'){
          this.mainMenuSelectedIndex = 12;
        } else if (url == '/profile') {
          this.mainMenuSelectedIndex = 13;
        } else if (url == '/change-password') {
          this.mainMenuSelectedIndex = 14;
        } else if (url == '/purchase'){
          this.selectedIndex = 0;
        }  else if (url == '/make'){
          this.selectedIndex = 1;
        } else if (url == '/category'){
          this.selectedIndex = 2;
        } else if (url == '/inventory'){
          this.selectedIndex = 3;
        } else if (url == '/unit-type'){
          this.selectedIndex = 4;
        } else if (url == '/low-stock'){
          this.selectedIndex = 5;
        }
    
        this.selectedPage = this.appPages.find(page => page.url === url);
      });

    this.menu.enable(true, 'custom');
    this.appPages = Admin;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.initializeApp();
    this.submenuOpen = new Array(this.appPages.length).fill(false);
    this.loading.getObservable().subscribe(data => {

      //console.log(data)
      if (data['type'] == 'Login') {
        if (data['value']) {

          this.getLoggedinUser()
        }
      }
    })

    // this.loading.getObservable().subscribe(data => {

    //   console.log(data)
    //   if (data['type'] == 'ProfileUpdate') {
    //     if (data['value']) {

    //       this.getLoggedinUser()
    //     }
    //   }
    // })

    this.loading.getObservable().subscribe(data => {

     // console.log(data)
      if (data['type'] == 'logout') {
        if (data['value']) {

          this.getLoggedinUser()
        }
      }
    })

  }

  
  ngOnInit() {
    this.menu.enable(true, 'custom');
    this.getLoggedinUser();

    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
    }
  
    var index = JSON.parse(localStorage.getItem('selectedIndex'))
    //console.log(index)
    if(index) {
      this.mainMenuSelectedIndex = index;
    }
    
  }

  toggleSubMenu(page: any) {
    if (this.selectedPage === page) {
      this.selectedPage = null;
    } else {
      this.selectedPage = page;
    }
  }

  isSubMenuOpen(page: any) {
    //console.log(page)
    return this.selectedPage === page;
  }

  // ionViewWillEnter() {
  //   this.getLoggedinUser();
  // }

  // getLoggedinUser() {
  //   this.masterservice.getLoggedinUser()
  //     .subscribe(
  //       res => {
  //         if (res.status == 1) {
  //           this.profile = res.data;
  //           console.log(this.profile)
  //         } else {
  //         }
  //       },
  //       err => {
  //         console.log("error", err);
  //       }
  //     )
  // }

  getLoggedinUser() {
    this.masterservice.getLoggedinUser()
      .subscribe(
        res => {
          if (res.status == 1) {
          this.profile = res.data;
         // console.log('this.profile',this.profile);
          if (this.profile.user_type == "2") {
            this.appPages = Accountant;
          } else if (this.profile.user_type == "3") {
            this.appPages = Staff;
          } else {
            this.appPages = Admin;
          }
        }
        },
        err => {
          // this.appPages = Admin;
          console.log("error", err);
        }
      )
  }


  profile_click() {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/login'])
    }
    this.menu.close()
  }
  
  toggleSubmenu(index: number): void {
    this.submenuOpen[index] = !this.submenuOpen[index];
    this.mainMenuSelectedIndex = index;
    localStorage.setItem('selectedIndex',JSON.stringify(this.mainMenuSelectedIndex ))
  }

  navigate(p) {
   // console.log(p);
    if(p.title == 'Inventory'){
      
    } else {
      let url = p.url;
      if (p.query) {
        url += '?'
        for (var key in p.query) {
  
         // console.log(key)
          url += key + '=' + p.query[key]
        }
      }
      this.router.navigateByUrl(url)
      this.submenuOpen = new Array(this.appPages.length).fill(false);
      this.selectedIndex = -1;
      this.menuCtrl.close();
    }
    
  }

  navigatesubmenu(p) {
    //console.log(index)
  // console.log(p)
   let url = p.url;
  // console.log(url)
      if (p.query) {
        url += '?'
        for (var key in p.query) {
  
          //console.log(key)
          url += key + '=' + p.query[key]
        }
      }
      this.router.navigateByUrl(url)
      this.menuCtrl.close();
  }

  subMenuindex(j){
    console.log(j)
  }
 

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();

      // subscribe to a topic
      this.fcm.subscribeToTopic('Deals');

      // get FCM token
      // this.fcm.getToken().then(token => {
      //   console.log(token);
      //   localStorage.setItem('device_token', JSON.stringify(token));
      // });


      // ionic push notification example
      this.fcm.onNotification().subscribe(data => {
        this.fcm.createNotificationChannel({
          id: 'com.mak.pushnotification', // required
          name: 'MAK', // required
          description: 'Very urgent message alert',
          importance: 'high', // https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
          visibility: 'public', // https://developer.android.com/training/notify-user/build-notification#lockscreenNotification
          sound: 'alert_sound', // In the "alert_sound" example, the file should located as resources/raw/alert_sound.mp3
          lights: false, // enable lights for notifications
          vibration: true, // enable vibration for notifications
        });

        //console.log(data);
        if (data.wasTapped) {
          // alert('Received in background' + JSON.stringify(data))
          //console.log('Received in background', data);
          let load = data;
          if (load.notification_type == 'inquiry_created') {
            this.router.navigate(['/inquiry']);
          } else if (load.notification_type == 'rfq_created') {
            if(this.profile.user_type == 3 ) {
              this.router.navigate(['/home']);
            } else {
              this.router.navigate(['/rfq']);
            }
          } else if (load.notification_type == 'survey_created') {
            this.router.navigate(['/survey']);
          } 
        } else {
          // alert('Received in foreground' + JSON.stringify(data))
           let load = data;
          localStorage.setItem('notificationData', JSON.stringify(load))
          //console.log('Received in foreground', data);
          if (load.notification_type == 'inquiry_created') {
            this.presentAlertNotification();
          } else if (load.notification_type == 'rfq_created') {
            if(this.profile.user_type == 3 ) {
              this.router.navigate(['/home']);
            } else {
              this.router.navigate(['/rfq']);
            }
            this.presentAlertNotification();
          } else if (load.notification_type == 'survey_created') {
            this.presentAlertNotification();
          } 
        }
      });

     
      // refresh the FCM token
      this.fcm.onTokenRefresh().subscribe(token => {
        console.log(token);
      });

      // unsubscribe from a topic
      this.fcm.unsubscribeFromTopic('offers');

    });
  }

  async presentAlertNotification() {
    var notificationData =  JSON.parse(localStorage.getItem('notificationData'))
     const alert = await this.alertController.create({
       cssClass: 'my-custom-class',
       header: this.translate.instant(notificationData.title),
       message: this.translate.instant(notificationData.message),
       buttons: [
         {
           text: this.translate.instant('Cancel'),
           role: 'cancel',
           cssClass: 'secondary',
           handler: (blah) => {
             console.log('Confirm Cancel: blah');
           },
         },
         {
           text: this.translate.instant('Okay'),
           handler: (res) => {
             this.goToPage(notificationData);
             console.log('Confirm Okay');
           },
         },
       ],
     });
     await alert.present();
   }

   goToPage(notificationData) {
     if (notificationData.notification_type == 'inquiry_created') {
      this.router.navigate(['/inquiry']);
    } else if (notificationData.notification_type == 'rfq_created') {
      if(this.profile.user_type == 3 ) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/rfq']);
      }
    } else if (notificationData.notification_type == 'survey_created') {
      this.router.navigate(['/survey']);
    } 
  }

  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     this.immersiveMode();
  //     this.statusBar.styleDefault();
  //     this.statusBar.backgroundColorByName('white');
  //     this.set_network();
  //     this.splashScreen.hide();
  //     this.counter = 0;
  //       this.platform.backButton.subscribe(() => {
  //         const userType = JSON.parse(localStorage.getItem('userType'));
  //         this.counter = this.counter + 1;
  //         if (this.counter == 2) {
  //           if(this.router.url === "/home"){
  //             this.presentAlertConfirm();
  //             setTimeout(() => { this.counter = 0; }, 1000)
  //           } else if(this.router.url === "/manager-home"){
  //             this.presentAlertConfirm();
  //             setTimeout(() => { this.counter = 0; }, 1000)
  //           } else if(this.router.url === "/specialist-home"){
  //             this.presentAlertConfirm();
  //             setTimeout(() => { this.counter = 0; }, 1000)
  //           }else{
  //             this.counter = 0;
  //             if(userType === 2){
  //               this.router.navigateByUrl('/manager-home')
  //              }else if(userType === 5 || userType === 3){
  //               this.router.navigateByUrl('/specialist-home')
  //              }else{
  //               this.router.navigateByUrl('/homepage')
  //              } 
  //           }        
  //         } else {
  //           console.log(this.counter)
  //            if(userType === 2){
  //             this.router.navigateByUrl('/manager-home')
  //            }else if(userType === 5 || userType === 3){
  //             this.router.navigateByUrl('/specialist-home')
  //            }else{
  //             this.router.navigateByUrl('/homepage')
  //            }            
  //         }
  //       }); 
  //   });
  // }

  immersiveMode() {
    this.androidFullScreen.isImmersiveModeSupported()
      .then(() => {
        this.androidFullScreen.immersiveMode().then(() => {
          // console.log('donefullscreen')
        })
          .catch((err => {
            // console.log('nofullscreen');
            console.log(err)
          }))
        // console.log('Immersive mode supported')
      })
      .catch(err => console.log(err));
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('Confirm!'),
      message: this.translate.instant('Are you sure want to exit?'),
      buttons: [
        {
          text: this.translate.instant('NOT NOW'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: this.translate.instant('EXIT APP'),
          cssClass: 'secondary',
          handler: () => {
            // console.log('Confirm Okay');
            navigator['app'].exitApp();
          }
        }
      ]
    });
    await alert.present();
  }

  set_network() {
    let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      let url = this.router.url;
      localStorage.setItem('no-internet', url);
      this.router.navigate(['/no-internet'])
    });
    let connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      setTimeout(() => {
        let url = localStorage.getItem('no-internet');
        localStorage.removeItem('no-internet');
      }, 2000);
    });
  }

  keyboard_setup() {
    this.keyboard.onKeyboardDidHide().subscribe((e) => {
      console.log('keyboardHide')
      this.immersiveMode()
    })
  }

  logout() {
    this.logoutConfirm()
  }

  async logoutConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Are you sure want to logout?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: (res) => {
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('user_type');
            localStorage.removeItem('rfqId');
            localStorage.removeItem('userType');
            localStorage.removeItem('project_id');
            localStorage.removeItem('device_token');
            localStorage.removeItem('notificationData');
            localStorage.removeItem('no-internet');
            localStorage.removeItem('inquiryId');
            localStorage.removeItem('customerId');
            localStorage.removeItem('custPostId');
            localStorage.removeItem('checkListId');
            localStorage.removeItem('workCompletionId');
            this.router.navigate(['/login']);
            // console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  getUserImage(): string {
    let initials = '';

    // Check if first_name and last_name are defined and not empty
    if (this.profile.first_name && this.profile.first_name.trim() !== '') {
      initials += this.profile.first_name.charAt(0);
    }

    if (this.profile.last_name && this.profile.last_name.trim() !== '') {
      initials += this.profile.last_name.charAt(0);
    }

    // Create the canvas and draw initials
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 100; // Set your desired width
    canvas.height = 100; // Set your desired height

    ctx.fillStyle = '#ccc'; // Set color for the text
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = '50px Arial'; // Set font size and family
    ctx.fillStyle = '#fff'; // Set color for the text
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(initials, canvas.width / 2, canvas.height / 2);

    // Convert canvas to data URL
    const dataURL = canvas.toDataURL();
    return dataURL;
  }
}
