// export const appConfig = {
//      apiUrl: 'https://redspark.a2hosted.com/maknetwork/api',
//      imgUrl: 'https://redspark.a2hosted.com/maknetwork/uploads/survey/',
// };

export const appConfig = {
     apiUrl: 'https://admin.maksolutions.ae/admin/api',
     imgUrl: 'https://admin.maksolutions.ae/admin/uploads/survey/',
     buildCreateFor: 'web',  // 'web' , 'android' , 'ios'
};

// export const appConfig = {
//      apiUrl: 'https://redspark.xyz/maknetwork/index.php/api',
//      imgUrl: 'https://redspark.xyz/maknetwork/index.php/uploads/survey/',

// };

// export const appConfig = {
//      apiUrl: 'https://redspark.xyz/maknetwork_test/index.php/api',
//      imgUrl: 'https://redspark.xyz/maknetwork_test/index.php/uploads/survey/',
//      buildCreateFor: 'web',  // 'web' , 'android' , 'ios'
// };


